body {
  min-width: 1050px;
  background-color: #4d5359;
  flex-direction: column;
  margin: 0;
  font-family: "Font Awesome 5 Regular", sans-serif;
  display: flex;
}

button {
  box-shadow: none;
  background: none;
  border: 0;
  border-radius: 6px;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.header {
  min-width: 1050px;
  background-color: #8d111b;
  justify-content: center;
  align-items: center;
  font-family: "Font Awesome 5 Regular", sans-serif;
  display: flex;
}

.hbox {
  width: 1200px;
  justify-content: space-between;
  margin: 20px 0;
  display: flex;
}

.headerLeft {
  color: #f0faf8;
  flex-direction: column;
  justify-content: space-around;
  font-family: "Font Awesome 5 Regular", sans-serif;
  font-size: 48px;
  font-weight: bolder;
  display: flex;
}

.headerRight {
  color: #e5e7eb;
  font-size: 18px;
  display: flex;
}

.header ul {
  gap: 16px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.content1 {
  min-width: 1050px;
  justify-content: space-around;
  margin: 50px 0;
  display: flex;
}

.content1box {
  flex-direction: column;
  justify-content: space-around;
  gap: 5px;
  display: flex;
}

.content1title {
  text-align: center;
  color: #f9faf8;
  font-size: 32px;
  font-weight: bolder;
}

.content1photo {
  max-width: 900px;
}

.content1third {
  color: #e5e7eb;
  font-size: 24px;
}

.content1box2 {
  width: 500px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.signUp {
  color: #e5e7eb;
  background-color: #3882f6;
  padding: 4px 30px;
  font-weight: bold;
}

.gradient1 {
  height: 100px;
  width: 800px;
}

.content2 {
  min-width: 1050px;
  background-color: #d1d1d1;
  flex-direction: column;
  align-content: space-around;
  margin: 0;
  font-size: 42px;
  font-weight: bold;
  display: flex;
}

.content2title {
  min-width: 1050px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
}

.icons {
  justify-content: center;
  gap: 100px;
  padding: 20px 0 100px;
  display: flex;
}

.iconContainer {
  display: inherit;
  flex-direction: column;
}

.iconSquare {
  display: inherit;
  height: 150px;
  width: 150px;
  background-color: #000;
  border: 5px solid #000;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.iconBtn {
  width: 140px;
}

.iconText {
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
}

.icons .box1 {
  text-align: center;
  width: 150px;
  flex-direction: column;
  font-size: 18px;
  display: flex;
}

.iconSquare:hover {
  transform: scale(1.1);
}

.iconSquare:active {
  transform: scale(.9);
}

.iconBtn img {
  filter: invert(95%) sepia(0%) saturate(343%) hue-rotate(219deg) brightness(92%) contrast(86%);
}

.box div {
  display: inherit;
  text-align: center;
  font-size: 16px;
}

.content3 {
  min-width: 1050px;
  text-align: center;
  color: #1f2937;
  background-color: #e5e7eb;
  flex-direction: column;
  justify-content: space-around;
  padding: 100px 0;
  font-size: 36px;
  font-style: italic;
  display: flex;
}

.c3box1 {
  display: inherit;
  justify-content: center;
  padding: 20px 0;
}

.bodyText {
  display: inherit;
  width: 600px;
}

.c3box2 {
  justify-content: center;
  display: flex;
}

.attributeText {
  text-align: right;
  width: 800px;
  font-weight: bold;
}

.content4 {
  min-width: 1050px;
  background-color: #fff;
  justify-content: center;
  display: flex;
}

.c4box {
  background-color: #3882f6;
  border-radius: 10px;
  gap: 200px;
  margin: 100px 0;
  padding: 40px 80px;
  display: flex;
}

.c4left {
  color: #f9faf8;
}

.c4left1 {
  font-size: 24px;
  font-weight: bold;
}

.c4left2 {
  color: #f0faf8;
  font-size: 18px;
}

.c4Right {
  border: 3px solid #fff;
  border-radius: 10px;
  place-content: center;
  display: flex;
}

.c4Right button {
  color: #e5e7eb;
  padding: 10px 40px;
  font-weight: bold;
}

.footer {
  color: #e5e7eb;
  justify-content: space-around;
  padding: 50px 0;
  font-size: 18px;
  display: flex;
}

/*# sourceMappingURL=index.e10db186.css.map */
